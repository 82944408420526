import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Wax } from "@eosdacio/ual-wax";



const myChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "wax.greymass.com",
      port: "443",
    },
  ],
};

const wax = new Wax([myChain], { appName: "waxloan" });

const MyUALConsumer = withUAL(App);

ReactDOM.render(
  <React.StrictMode>
    <UALProvider
      chains={[myChain]}
      authenticators={[wax]}
      appName={"waxloan"}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MyUALConsumer />} />
        </Routes>
      </BrowserRouter>
    </UALProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
